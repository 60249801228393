<template>
  <div class="content flex-box vertical">
    <data-list
      ref="dataList"
      class="flex-grow"
      :url="url"
      :query="query"
      :default-query="defaultQuery"
      entity-name="出库清单"
      hide-show-detail
      show-row-num
      :rules="rules"
      :columns="columns"
      :search-key-type="searchKeyType"
      :before-edit="beforeEdit"
      :before-update="beforeUpdate"
      :requestConfig="{ noTempleFilter: true }"
      :width="1500"
      :action="action"
    >
      <template v-slot:action="{ item }">
        <!--      根据 type显示隐藏  {{item.id}}-->
        <a class="txt-btn" @click.stop="handleReturn(item)" v-if="item.category == 4 && item.repay_status == 1">
          确认归还
        </a>
      </template>

      <!-- <template v-slot:button>
        <a-dropdown>
          <a-button type="primary">更多操作</a-button>
          <template #overlay>
            <a-menu @click="handleMenuClick">
              <a-menu-item key="1">导入数据库</a-menu-item>
              <a-menu-item key="2">下载数据模板</a-menu-item>
              <a-menu-item key="3">导出EXCEl</a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template> -->
      <template v-slot:button>
        <a-button type="primary" @click="exportData" :loading="exporting">
          {{ exporting ? "正在导出" : "导出EXCEL" }}
        </a-button>
        <upload-file uploadText="导入清单" type="primary" :url="`${url}/import`" @change="updateList"></upload-file>
        <a-button @click="downloadTemplate">下载模板</a-button>
      </template>

      <template v-slot:query="{ form }">
        <a-form-model-item label="主管">
          <select-book type="user" v-model="form.director" @change="updateList"></select-book>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px"
            placeholder="选择出库类别"
            :options="outboundOptions"
            @change="categoryChange"
            v-model="form.category"
            allowClear
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.goods_name" placeholder="输入商品名称/SKU" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px"
            placeholder="选择堂口"
            @change="updateList"
            :options="tkOptions"
            v-model="form.tangkou_id"
            allowClear
            show-search
            option-filter-prop="children"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-range-picker
            v-model="form.ex_warehouse_date"
            :placeholder="['出库开始日期', '出库截止日期']"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            allowClear
            @change="updateList"
            class="range-picker"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.ex_warehouse_number" placeholder="出库单编号" allowClear></a-input>
        </a-form-model-item>
        <!-- <a-form-model-item>
          <a-input v-model="form.fixed_assets_number" placeholder="固定资产编号" allowClear></a-input>
        </a-form-model-item> -->
        <a-form-model-item>
          <a-input v-model="form.notes" placeholder="备注" allowClear></a-input>
        </a-form-model-item>
      </template>

      <template v-slot:edit="{ form }">
        <a-form-model-item label="出库类别" prop="category">
          <a-select placeholder="选择出库类别" :options="outboundOptions" v-model="form.category" allowClear>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="出库日期">
          <!-- <a-date-picker
            style="width: 100%"
            v-model="form.ex_warehouse_date"
            placeholder="请选择出库日期"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            :allow-clear="false"
            disabled
          /> -->
          {{ form.ex_warehouse_date || "-" }}
        </a-form-model-item>

        <template v-if="form.category == 4">
          <a-form-model-item label="借用日期">
            <!-- <a-date-picker
              style="width: 100%"
              v-model="form.borrow_date"
              placeholder="请选择借用日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              allowClear
              disabled
            /> -->
            {{ form.borrow_date || "-" }}
          </a-form-model-item>

          <a-form-model-item label="归还日期" prop="repay_date">
            <a-date-picker
              style="width: 100%"
              v-model="form.repay_date"
              placeholder="请选择归还日期"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              allowClear
            />
          </a-form-model-item>
        </template>

        <a-form-model-item label="商品名称" prop="goods_id" :extra="getCommodityExtra(form)">
          <GoodsSelect v-model="form.goods_id" :form="form" :options="goodsOptions" />
        </a-form-model-item>

        <a-form-model-item label="出库数量" prop="ex_warehouse_num">
          <a-input-number
            v-model="form.ex_warehouse_num"
            placeholder="请输入出库数量"
            :min="1"
            :step="1"
            style="width: 100%"
            allowClear
          ></a-input-number>
        </a-form-model-item>

        <!-- <a-form-model-item label="固定资产编号">
          <a-input v-model="form.order_num" placeholder="请输入固定资产编号" allowClear></a-input>
        </a-form-model-item> -->

        <a-form-model-item label="堂口名称" prop="tangkou_id">
          <a-select
            style="width: 100%;"
            placeholder="选择堂口"
            :options="tkOptions"
            v-model="form.tangkou_id"
            allowClear
            show-search
            option-filter-prop="children"
          >
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="部门主管" prop="director">
          <select-book type="user" v-model="form.director"></select-book>
        </a-form-model-item>

        <a-form-model-item label="备注">
          <a-textarea v-model="form.notes" :rows="4" placeholder="填写出库备注" allowClear></a-textarea>
        </a-form-model-item>
      </template>
    </data-list>

    <!--    确认是否归还-->
    <a-modal
      :maskClosable="false"
      v-model="visible"
      :width="400"
      title="确认归还"
      ok-text="确认"
      cancel-text="取消"
      @ok="confirm"
      @cancel="cancel"
    >
      <a-form-model
        class="item-form"
        ref="editForm"
        :model="formData"
        :rules="formRules"
        :labelCol="modalForm.labelCol"
        :wrapperCol="modalForm.wrapperCol"
        :layout="modalForm.layout"
        :labelAlign="modalForm.labelAlign"
      >
        <a-form-model-item label="商品名称" :extra="getRepayCommodityExtra">
          {{ formData.goods && formData.goods.name }}
        </a-form-model-item>

        <a-form-model-item label="商品数量" prop="num">
          <a-input-number
            style="width: 100%"
            v-model="formData.num"
            placeholder="请输入商品数量"
            :min="1"
            :max="formData.ex_warehouse_num - formData.repay_num"
            :step="1"
            allowClear
          ></a-input-number>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { outboundOptions } from "../../common/asset/type";
import {
  clone,
  dataConvertOptions,
  downLoadBlobFile,
  downLoadFile,
  getKeyTitle,
  getQuery,
  stringConvertTimestamp,
  timestampConvertString,
} from "../../common/js/tool";
import Vue from "vue";
import { getTemple } from "../../common/js/storage";
import { outboundTemplateUrl } from "../../common/asset/template";
import GoodsSelect from "./components/GoodsSelect.vue";
// eslint-disable-next-line no-unused-vars
const h = new Vue().$createElement;

const DEFAULT_COLUMNS = [
  {
    title: "SKU",
    width: 80,
    customRender: (text) => {
      return text?.goods?.sku || "-";
    },
  },
  {
    title: "日期",
    dataIndex: "ex_warehouse_date",
    width: 120,
    customRender: (text) => {
      return timestampConvertString(text, "yyyy-MM-dd") || "-";
    },
  },
  { title: "出库单编号", dataIndex: "ex_warehouse_number", width: 120 },
  {
    title: "出库类别",
    dataIndex: "category",
    width: 100,
    customRender: (text) => {
      return <a-tag>{getKeyTitle(outboundOptions, text, "value", "label")}</a-tag>;
    },
  },
  {
    title: "堂口-主管",
    width: 180,
    customRender: (text) => {
      let tangkou_name = text.tangkou?.name || text.tangkou_name;
      let director = text.director;
      if (!tangkou_name && !director) {
        return "-";
      }
      return (
        <div>
          <span>{tangkou_name}-</span>
          <open-data type="userName" openid={director} />
        </div>
      );
    },
  },
  {
    title: "商品名称",
    width: 140,
    customRender: (text) => {
      return text?.goods?.name || "-";
    },
  },
  {
    title: "单位",
    width: 60,
    customRender: (text) => {
      return text?.goods?.goodsUnit?.name || "-";
    },
  },
  { title: "数量", dataIndex: "ex_warehouse_num", width: 60 },
  { title: "平均价(元)", dataIndex: "average_price", width: 100 },
  { title: "金额(元)", dataIndex: "price", width: 80 },
];

const dateKeys = ["ex_warehouse_date", "borrow_date", "repay_date"];

export default {
  name: "OutboundList",
  components: {
    GoodsSelect,
  },
  data() {
    return {
      url: "/admin/asset-ex-warehouse",
      defaultQuery: {
        category: 1,
      },
      searchKeyType: {
        ex_warehouse_number: 1,
        director: 2,
        category: 2,
        goods_name: 2,
        ex_warehouse_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&start_date=${stringConvertTimestamp(value[0])}&end_date=${stringConvertTimestamp(value[1])}`;
          }
          return res;
        },
        tangkou_id: 2,
        notes: 2,
      },
      columns: clone(DEFAULT_COLUMNS),
      rules: {
        category: [{ required: true, message: "请选择出库类型", trigger: "change" }],
        ex_warehouse_date: [{ required: true, message: "请选择出库日期", trigger: "change" }],
        borrow_date: [{ required: true, message: "请选择借用日期", trigger: "change" }],
        repay_date: [{ required: true, message: "请选择归还日期", trigger: "change" }],
        goods_id: [{ required: true, message: "请选择商品", trigger: "change" }],
        ex_warehouse_num: [{ required: true, message: "请输入出库数量", trigger: "blur" }],
        tangkou_id: [{ required: true, message: "请选择堂口", trigger: "change" }],
        director: [{ required: true, message: "请选择部门主管", trigger: "change" }],
      },
      tkOptions: [],
      modalForm: { layout: "vertical", labelAlign: "left" },
      formRules: {
        num: [{ required: true, message: "请输入商品数量", trigger: "change" }],
      },
      formData: {},
      visible: false,
      outboundOptions,
      exporting: false,

      goodsOptions: [],
    };
  },
  computed: {
    query() {
      let temple = getTemple();
      return `&temple_id=${temple}&sort=-ex_warehouse_date,-create_time&expand=tangkou,goods.goodsUnit`;
    },
    action() {
      return this.$route.meta.action;
    },
  },
  created() {
    this.getTk();
  },
  methods: {
    getTk() {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "order,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.tkOptions = dataConvertOptions(res.data);
      });
    },
    beforeEdit(form, fn) {
      if (form.goods_id && form.goods) {
        const goods = form.goods;
        const option = { ...goods, label: goods.name, value: goods.id };
        this.goodsOptions = [option];
      }
      dateKeys.forEach((key) => {
        if (form[key]) {
          form[key] = timestampConvertString(form[key], "yyyy-MM-dd");
        }
      });
      fn(form);
    },
    beforeUpdate(form, fn) {
      dateKeys.forEach((key) => {
        if (form[key]) {
          form[key] = stringConvertTimestamp(form[key]);
        }
      });
      fn(form);
    },
    getList() {
      this.$refs.dataList.getList();
    },
    updateList() {
      this.$refs.dataList.updateList();
    },
    categoryChange(val) {
      let columns = clone(DEFAULT_COLUMNS);
      if (val == 4) {
        columns.splice(
          1,
          0,
          {
            title: "借用日期",
            dataIndex: "borrow_date",
            width: 120,
            customRender: (text) => {
              return timestampConvertString(text, "yyyy-MM-dd") || "-";
            },
          },
          {
            title: "归还日期",
            dataIndex: "repay_date",
            width: 120,
            customRender: (text) => {
              return timestampConvertString(text, "yyyy-MM-dd") || "-";
            },
          }
        );
        columns.push({
          title: "归还状态",
          width: 160,
          customRender: (text) => {
            let res = "-";
            const { repay_status, ex_warehouse_num, repay_num = 0 } = text;
            if (repay_status == 1) {
              res = "未归还";
              let residue_num = ex_warehouse_num - repay_num;
              if (repay_num > 0) {
                res = `已还${repay_num},未还${residue_num}`;
              }
            } else if (repay_status == 2) {
              res = "已归还";
            }
            return res;
          },
        });
      }
      this.columns = columns;
      this.updateList();
    },
    getCommodityExtra(form) {
      let res = null;
      if (form.goods_id) {
        res = `当前库存：${form.goods?.goods_num || 0}${form.goods?.goodsUnit?.name}`;
      }
      return res;
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    //确认归还点击
    handleReturn(item) {
      this.formData = clone(item);
      this.visible = true;
    },
    getRepayCommodityExtra() {
      const { repay_num, ex_warehouse_num, goods } = this.formData;
      let unit = goods?.goodsUnit?.name;
      return `已还${repay_num || 0}${unit},未还${ex_warehouse_num - repay_num}${unit}`;
    },
    cancel() {
      this.formData = {};
      this.visible = false;
    },
    confirm() {
      this.$refs.editForm.validate((flag) => {
        if (flag) {
          let form = this.formData;
          this.$axios({
            url: `${this.url}/${form.id}`,
            method: "PATCH",
            data: { num: form.num },
          }).then(() => {
            this.$message.success("更新成功");
            this.visible = false;
            this.getList();
          });
        }
      });
    },
    downloadTemplate() {
      downLoadFile(outboundTemplateUrl, "出库模板");
    },
    exportData() {
      if (this.exporting) return;
      this.exporting = true;
      let temple = getTemple();
      let url = `${this.url}/export?temple_id=${temple}` + getQuery.call(this.$refs.dataList, this.searchKeyType);
      this.$axios(url, { noTempleFilter: true, responseType: "blob" })
        .then((res) => {
          downLoadBlobFile(res, "出库数据");
        })
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  color: #909090;
  font-size: 12px;
  margin-top: 8px;
}
</style>
